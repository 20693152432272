import Container from "components/Container"
import Section from "components/Section"

import { H2 } from "styles/Type"

import { AccordionProps } from "./types"
import AccordionItem from "./Item"

export const Accordion = ({
  title,
  items,
  section,
  children,
}: AccordionProps) => (
  <Section {...section}>
    <Container>
      {title && <H2 className="mb-8">{title}</H2>}
      {items?.map((item, idx) => (
        <AccordionItem key={[idx, item.title].join("_")} idx={idx} {...item} />
      ))}
      {children}
    </Container>
  </Section>
)

export default Accordion
