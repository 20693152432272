import { useRef, useCallback, useState, useMemo } from "react"
import classNames from "classnames"

import slugify from "lib/slugify"

import { H3, Lead } from "styles/Type"

import PlusIcon from "icons/Plus"

import { AccordionButton, AccordionBody } from "./styles"
import type { AccordionItemProps } from "./types"

export const AccordionItem = ({ title, content, idx }: AccordionItemProps) => {
  const bodyRef = useRef<HTMLParagraphElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = useCallback(() => {
    setIsOpen((open) => {
      window?.dataLayer?.push({
        event: "accordion_engagement",
        engagement_type: open ? "close" : "open",
        accordion_title: title,
      })
      return !open
    })
  }, [title])

  const [buttonID, bodyID] = useMemo(
    () => [
      slugify([idx, title, "title"].join("_")),
      slugify([idx, title].join("_")),
    ],
    [idx, title]
  )

  return (
    <div className="border-b border-smokey border-opacity-20 flex flex-col">
      <h3>
        <AccordionButton
          type="button"
          aria-controls={bodyID}
          id={buttonID}
          aria-expanded={isOpen ? "true" : "false"}
          onClick={toggle}
        >
          <H3 as="div">{title}</H3>
          <PlusIcon
            className={classNames(
              "ml-4",
              "transition-transform duration-150",
              "flex-shrink-0",
              isOpen && "-rotate-45"
            )}
            aria-hidden="true"
          />
        </AccordionButton>
      </h3>
      <AccordionBody
        id={bodyID}
        role="region"
        aria-labelledby={buttonID}
        className={classNames(!isOpen && "invisible", isOpen && "mb-12")}
        style={{
          height: isOpen ? bodyRef.current.getBoundingClientRect().height : 0,
        }}
      >
        <div className="max-w-[75ch]" ref={bodyRef}>
          {content
            ?.split("\n")
            ?.filter((c) => !!c)
            ?.map((c) => (
              <Lead key={c} className="mb-5">
                {c}
              </Lead>
            ))}
        </div>
      </AccordionBody>
    </div>
  )
}

export default AccordionItem
