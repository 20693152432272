export const themes = {
  white: "bg-white",
  "off-white": "bg-off-white",
  smokey: "bg-smokey text-white",
  camo: "bg-camo text-white",
  forest: "bg-forest text-white",
  green: "bg-green",
  black: "bg-black text-white accent-dirt-gold",
  "dirt-gold": "bg-dirt-gold",
  custom: "",
}

export const paddings = {
  xlarge: "lg:py-40 py-14",
  large: "lg:py-24 py-14",
  medium: "lg:py-16 py-10",
  custom: "",
}
