import { useEffect, useRef, useState } from "react"

export const useScreenEntry = (defaultState = true, rehide = false) => {
  const elementRef = useRef<HTMLDivElement>(null)
  const [onScreen, setOnScreen] = useState(defaultState)
  const [visibleRatio, setVisibleRatio] = useState(0)

  useEffect(() => {
    if (!elementRef?.current) {
      return () => {}
    }
    setOnScreen(false)
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setOnScreen(true)
        } else {
          setOnScreen((os) => os && !rehide)
        }
        setVisibleRatio(entry.intersectionRatio)
      },
      {
        threshold: Array(50)
          .fill("")
          .map((x, idx) => idx * (1 / 50)),
        rootMargin: "-20% 0px -20% 0px",
      }
    )
    observer.observe(elementRef.current)
    return () => observer.disconnect()
  }, [rehide])

  return { onScreen, elementRef, visibleRatio }
}
export default useScreenEntry
