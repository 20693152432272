import { Fragment } from "react"
import classNames from "classnames"

import useScreenEntry from "lib/useScreenEntry"

import Section from "components/Section"
import RoundImage from "components/Image/RoundImage"
import Button from "components/Button"
import Container from "components/Container"
import Grid from "components/Grid"
import Tag from "components/Tag"

import { H2, H3, Lead, P } from "styles/Type"
import icons from "icons"

import { HalfContentProps } from "./types"

export const HalfContent = ({
  subtitle,
  title,
  content,
  buttons,
  image,
  section,
  reverse,
  mobileReverse,
  subtitleIcon,
  list,
  imageNode = null,
  children,
}: HalfContentProps) => {
  const { elementRef, onScreen } = useScreenEntry()
  const Icon = icons?.[subtitleIcon] || null

  return (
    <Section className="overflow-hidden" ref={elementRef} {...section}>
      <Container>
        <Grid className="items-center">
          <div
            className={classNames(
              "col-span-full md:col-span-6 lg:col-span-5",
              reverse && "lg:col-start-8"
            )}
          >
            {subtitle && (
              <Tag
                className={classNames(
                  "mb-7",
                  "transition duration-500",
                  !onScreen && "opacity-0 motion-safe:translate-y-20"
                )}
                as="h2"
              >
                {subtitleIcon && Icon && (
                  <Icon className="w-4 h-4 -mt-1 inline-block mr-1.5" />
                )}
                {subtitle}
              </Tag>
            )}
            {title && (
              <H2
                className={classNames(
                  "mb-7",
                  "transition duration-500 delay-100",
                  !onScreen && "opacity-0 motion-safe:translate-y-20"
                )}
                as={subtitle ? "h3" : "h2"}
              >
                {title}
              </H2>
            )}
            {content && (
              <Lead
                className={classNames(
                  "text-camo mb-10",
                  "transition duration-700 delay-200",
                  !onScreen && "opacity-0 motion-safe:translate-y-20"
                )}
              >
                {content.split("\n").map((c, idx) =>
                  idx === 0 ? (
                    c
                  ) : (
                    <Fragment key={[c, idx].join("_")}>
                      <br />
                      <br />
                      {c}
                    </Fragment>
                  )
                )}
              </Lead>
            )}
            {list && (
              <P
                as="ol"
                className={classNames(
                  "transition duration-700 delay-200",
                  !onScreen && "opacity-0 motion-safe:translate-y-20"
                )}
              >
                {list.map((c, idx) => (
                  <li key={[c, idx].join("_")} className="flex mb-5">
                    <H3
                      as="div"
                      className="w-9 h-9 mr-4 bg-black text-white rounded-full flex items-center justify-center pb-1 flex-shrink-0"
                    >
                      {idx + 1}
                    </H3>
                    {c}
                  </li>
                ))}
              </P>
            )}
            <div
              className={classNames(
                !onScreen && "opacity-0 motion-safe:translate-y-20",
                "transition duration-700 delay-300"
              )}
            >
              {children}
            </div>
            {buttons && (
              <div
                className={classNames(
                  "flex flex-wrap -m-2 mb-7 md:-mb-2",
                  "transition duration-700 delay-300",
                  !onScreen && "opacity-0 motion-safe:translate-y-20"
                )}
              >
                {buttons.map((button) => (
                  <Button
                    key={Object.values(button).join("-")}
                    {...button}
                    className="m-2"
                  />
                ))}
              </div>
            )}
          </div>
          <div
            className={classNames(
              "col-span-full",
              "md:col-span-6",
              "mb-14 lg:mb-0",
              mobileReverse ? "row-start-1" : "lg:row-start-1",
              !reverse && "lg:col-start-7"
            )}
          >
            {imageNode && (
              <div
                className={classNames(
                  "transition duration-500 delay-200",
                  !onScreen && "opacity-0 motion-safe:translate-y-20"
                )}
              >
                {imageNode}
              </div>
            )}
            {image && (
              <RoundImage
                className={classNames(
                  mobileReverse ? "mb-14 md:mb-0" : "mt-14 md:mt-0",
                  "transition duration-500 delay-200",
                  !onScreen && "opacity-0 motion-safe:translate-y-20"
                )}
                {...image}
              />
            )}
          </div>
        </Grid>
      </Container>
    </Section>
  )
}

export default HalfContent
