import { FC, HTMLAttributes } from "react"
import classNames from "classnames"

import { H5 } from "styles/Type"

export const Tag: FC<
  HTMLAttributes<HTMLDivElement> & { as?: keyof JSX.IntrinsicElements }
> = ({ className, as = "h2", children }) => (
  <div
    className={classNames(
      "inline-block bg-accent text-accent-fg rounded-xl text-white p-2.5",
      className
    )}
  >
    <H5 as={as}>{children}</H5>
  </div>
)

export default Tag
