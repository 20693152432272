import createStyle from "lib/createStyle"

export const AccordionButton = createStyle(
  "button",
  "flex w-full items-center justify-between py-7 bg-transparent text-left"
)

export const AccordionBody = createStyle(
  "div",
  "overflow-hidden transition-all duration-300"
)
