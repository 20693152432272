import classNames from "classnames"
import createStyle from "lib/createStyle"
import { forwardRef } from "react"

import { themes, paddings } from "./consts"
import { SectionProps } from "./types"

const SectionEl = createStyle("section")

export const Section = forwardRef<HTMLDivElement, SectionProps>(
  (
    { theme = "white", padding = "medium", children, className = "", ...props },
    ref
  ) => (
    <SectionEl
      className={classNames(className, themes[theme], paddings[padding])}
      ref={ref}
      {...props}
    >
      {children}
    </SectionEl>
  )
)

export default Section
