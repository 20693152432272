import classNames from "classnames"
import Image from "components/Image"
import { H4 } from "styles/Type"

import { RoundImageProps } from "./types"

export const RoundImage = ({
  caption_title,
  caption_text,
  className,
  children,
  bg = true,
  ...image
}: RoundImageProps) => {
  return (
    <div className={classNames("w-full pb-[100%] relative", className)}>
      <figure
        className={classNames("absolute -inset-14 md:inset-0 xl:-inset-6 ")}
      >
        <div
          className={classNames(
            "absolute w-full h-full",
            "rounded-full overflow-hidden",
            bg && "bg-forest"
          )}
        >
          {children}
          <Image
            {...image}
            width="636"
            height="636"
            layout="responsive"
            sizes="636px"
          />
        </div>
        {(caption_title || caption_text) && (
          <figcaption className="absolute -bottom-5 left-1/2 -translate-x-1/2 py-5 px-10 text-center w-[290px] bg-accent text-accent-fg rounded-full">
            {caption_title && (
              <H4 as="div" className="mb-2 last:mb-0">
                {caption_title}
              </H4>
            )}
            {caption_text}
          </figcaption>
        )}
      </figure>
    </div>
  )
}
export default RoundImage
